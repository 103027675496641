<script>
    export default {
        props: ['teamDealData'],
        data() {
            return {
                showModal: false,
            };
        },
        methods: {

            closeModal() {
                this.showModal = false;

            },

        }
    };
</script>


<template>
    <b-modal id="view_team_deal" size="xl" v-model="showModal" title="Deal" title-class="font-18">
        <div class="row mb-3" v-if="teamDealData">
            <div class="col-sm-6">
                <p class="font-size-16 mb-2"><span class="text-strong">Name:</span> {{teamDealData.name}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Description:</span> {{teamDealData.description}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Discount:</span> {{teamDealData.discount}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Discount Type:</span> {{teamDealData.discount_type}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Minimum Amount:</span> {{teamDealData.min_amount}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Currency:</span> {{teamDealData.currency_code}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Valid From:</span> {{teamDealData.valid_from}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Valid To:</span> {{teamDealData.valid_to}}</p>


            </div>
            <div class="col-sm-6">
                <p class="font-size-16 mb-2"><span class="text-strong">Logo URL:</span> {{teamDealData.logo_url}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Display URL:</span> {{teamDealData.display_url}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Click Through URL:</span>
                        <a :href="teamDealData.click_through_url">  Click Through URL</a> </p>
                <p class="font-size-16 mb-2"><span class="text-strong">Region:</span> {{teamDealData.region_name}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Region Code:</span> {{teamDealData.region_country_code}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Primary Sector:</span> {{teamDealData.primary_sector}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Terms:</span> {{teamDealData.terms}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Status: <span v-html="format_status(teamDealData.status)"></span></span> </p>
                <p class="font-size-16 mb-2"><span class="text-strong">Image Small: </span>
                        <span v-if="teamDealData.deal_img_small"><img :src="teamDealData.deal_img_small" contain width="100px"></span>
                        <span class="text-strong">Image Large: </span>
                        <span v-if="teamDealData.deal_img_large"><img :src="teamDealData.deal_img_large" contain width="100px"></span>
                </p>

            </div>

        </div>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
        </template>
    </b-modal>

</template>