<script>
 import Advertising from '@/services/Advertising';
 import ModalAddTeamDeal from "@/components/modals/advertising/modalAddTeamDeal";

    export default {
     components: { ModalAddTeamDeal },
        props: ['dealData'],
        data() {

            return {
                showModal: false,
                promotions:[],
                teamDealData:[],
            };
        },
        methods: {

            closeModal() {
                this.showModal = false;
                this.refreshData();
            },
             initialPromotions(){
                this.getPromotions();
            },

            callModalAddTeamDeal(data) {
                data.id = this.dealData.id;
                data.name = this.dealData.name;
                data.description = this.dealData.description;
                data.currency_code = this.dealData.currency_code;
                data.logo_url = this.dealData.logo_url;
                data.display_url = this.dealData.display_url;
                data.click_through_url = data.url_tracking;
                data.region_name = this.dealData.region_name;
                data.region_country_code = this.dealData.region_country_code;
                data.primary_sector = this.dealData.primary_sector;
                data.status = this.dealData.status;
                data.manual = 0;

                this.teamDealData = data;
                //this.teamDealData = this.dealData;
                this.$bvModal.show("add_team_deal");


            },

            callModalAddTeamDealGeneral() {
                this.dealData.manual = 0;
                this.teamDealData = this.dealData;
                this.$bvModal.show("add_team_deal");
                this.refreshData();
            },

            async getPromotions(){
               var filter_string = 'advert_id=' + this.dealData.id;
               const response = await Advertising.getPromotions(filter_string);
               this.promotions = response.data.data;


            },

             refreshData() {
                this.$emit('onRefresh') //event from parent
             }

        }
    };
</script>



<template>
    <b-modal id="view_deal"  @shown="initialPromotions" size="xl" v-model="showModal" title="Deal" title-class="font-18">
        <div class="row mb-3" v-if="dealData">
            <div class="col-sm-6">
                <p class="font-size-16 mb-2"><span class="text-strong">Name:</span> {{dealData.name}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Description:</span> {{dealData.description}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Currency:</span> {{dealData.currency_code}}</p>



            </div>
            <div class="col-sm-6">
                <p class="font-size-16 mb-2"><span class="text-strong">Logo URL:</span> {{dealData.logo_url}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Display URL:</span> {{dealData.display_url}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Click Through URL:</span> {{dealData.click_through_url}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Region:</span> {{dealData.region_name}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Region Code:</span> {{dealData.region_country_code}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Primary Sector:</span> {{dealData.primary_sector}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Status: <span v-html="format_status(dealData.status)"></span></span> </p>

            </div>

             <!-- <div class="row mt-3"  v-if="dealData.promotions && dealData.promotions.length"> -->
             <div class="row mt-3"  v-if="promotions && promotions.length">
                    <div class="col-sm-12">
                        <h5>Promotions</h5>
                       <div class="table-responsive">
                           <table class="table mt-3 font-size-12">
                               <thead>
                               <th>Type</th>
                               <th>Voucher Code</th>
                               <th>Title</th>
                               <th>Description</th>
                               <th>Terms</th>
                               <th>Start Date</th>
                               <th>End Date</th>
                               <th>Url</th>
                               <th>Url Tracking</th>
                               <th>Date Added</th>
                               <th>Campaign</th>
                               <th>Add</th>

                               </thead>
                               <tbody>
                               <tr v-for="item in promotions" :key="item.promotion_id">
                                   <td>{{item.type}}</td>
                                   <td>{{item.voucher_code}}</td>
                                   <td>{{item.title}}</td>
                                   <td>{{item.description}}</td>
                                   <td>{{item.terms}}</td>
                                   <td>{{item.start_date}}</td>
                                   <td>{{item.end_date}}</td>
                                   <td> <a :href="item.url">URL </a></td>
                                   <td> <a :href="item.url_tracking">URL Tracking</a></td>
                                   <td>{{item.date_added}}</td>
                                   <td>{{item.campaign}}</td>
                                   <td> <button  type="button"  v-if="item.promotion_added == 0 "
                                         @click="callModalAddTeamDeal(item)"
                                         class="btn btn-sm btn-success font-size:10" title="Add Deal to Club" >
                                       Add
                                       </button>
                                    </td>

                               </tr>
                               </tbody>
                         </table>
                     </div>
                  </div>
              </div>

              <div class="row mt-3"  v-else>
                    <div class="col-12">
                      <div>
                          <button type="button" class="btn btn-success mb-3" @click="callModalAddTeamDealGeneral()">
                              <i class="mdi mdi-plus me-1"></i> Add Deal to Club
                          </button>
                      </div>
                    </div>
              </div>


        </div>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
        </template>

    <ModalAddTeamDeal :teamDealData="teamDealData"  @onRefresh="getPromotions();"></ModalAddTeamDeal>
    </b-modal>

</template>